import React, { useState, useCallback } from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { isNull, findIndex } from "lodash"

import MemeberCard from "components/MemberCard"
import MemberDrawer from "components/MemberDrawer"
import MemberDetails from "components/MemberDetails"
import PageHeader from "components/PageHeader"
import Seo from "components/Seo"

export default function ConsultantsPage({ data }) {
  const consultants = data.allWpConsultant.nodes
  const [visibleConsultantDetails, setVisibleConsultantDetails] = useState(null)
  const selectedConsultantIndex = findIndex(
    consultants,
    item => item.id === visibleConsultantDetails
  )
  const selectedConsultant =
    selectedConsultantIndex !== -1 ? consultants[selectedConsultantIndex] : null

  const renderSelectedConsultant = useCallback(() => {
    return <MemberDetails source={selectedConsultant} />
  }, [selectedConsultant])

  return (
    <div>
      <Seo
        title={"전문 컨설턴트 소개"}
        description="HPC컨설팅은 HRM, HRD, 제조혁신 분야의 풍부한 경험을 가진 최고 수준의 전문가로 구성되어 있습니다."
      />
      <MemberDrawer
        open={!isNull(visibleConsultantDetails)}
        onClose={() => setVisibleConsultantDetails(null)}
      >
        {selectedConsultant && renderSelectedConsultant()}
      </MemberDrawer>
      <PageHeader
        title={"HPC컨설팅 전문 컨설턴트"}
        description={
          "HPC컨설팅은 HRM, HRD, 제조혁신 분야의 풍부한 경험을 가진 최고 수준의 전문가로 구성되어 있습니다."
        }
      />
      <div className="w-full lg:container mx-auto px-4 lg:px-8 hidden">
        <div className="flex flex-row flex-wrap -mx-4 lg:-mx-8 mb-8">
          {[
            {
              key: "hr",
              label: "HR분야",
              children: [
                { label: "공인노무사", count: 5, suffix: "명" },
                { label: "현업경력", count: 4, suffix: "명" },
                { label: "인사조직 석·박사", count: 2, suffix: "명" },
              ],
            },
            {
              key: "manufacturing",
              label: "제조분야",
              children: [
                { label: "PI 전문가", count: 2, suffix: "명" },
                { label: "현업경력", count: 4, suffix: "명" },
                { label: "스마트팩토리 전문가", count: 1, suffix: "명" },
              ],
            },
          ].map(group => {
            return (
              <div
                key={group.key}
                className="w-full lg:w-1/2 px-4 lg:px-8 mb-8"
              >
                <div className="">
                  <h3 className="text-gray-500 mb-3 border-b pb-1">
                    {group.label}
                  </h3>
                  <div className="flex flex-row flex-wrap -mx-4 lg:-mx-8 -my-3 lg:my-0">
                    {group.children.map((item, index) => {
                      return (
                        <div
                          className="w-1/2 sm:w-1/3 lg:w-auto px-4 lg:px-8 py-3 lg:py-0"
                          key={`${group.key}-${index}`}
                        >
                          <h4 className="text-primary font-medium">
                            {item.label}
                          </h4>
                          <p>
                            <span className="text-gray-900 text-2xl font-medium">
                              {item.count}
                            </span>
                            <span className="text-gray-500 ml-1">
                              {item.suffix}
                            </span>
                          </p>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div>
        <div className="w-full lg:container mx-auto px-4 lg:px-8">
          <div className="flex flex-row flex-wrap -mx-4 lg:-mx-8">
            {consultants.map((item, index) => {
              const featuredImage = {
                data: item.featuredImage?.node?.localFile?.childImageSharp
                  ?.gatsbyImageData,
                alt: item.featuredImage?.node?.alt || ``,
              }
              return (
                <div
                  key={item.id}
                  className={`w-1/2 xl:w-1/4 px-4 lg:px-8 group mb-8`}
                >
                  <MemeberCard
                    onClick={() => setVisibleConsultantDetails(item.id)}
                    avatar={
                      featuredImage?.data ? (
                        <GatsbyImage
                          style={{
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                            inset: 0,
                            objectFit: "cover",
                          }}
                          image={featuredImage.data}
                          alt={featuredImage.alt}
                        />
                      ) : undefined
                    }
                    speciality={item.consultant?.speciality}
                    role={item.consultant?.role}
                    name={item.title}
                    id={item.id}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export const pageQuery = graphql`
  query MyQuery {
    allWpConsultant(sort: { fields: [date], order: ASC }) {
      nodes {
        consultant {
          role
          speciality
          awards
          education
          experience
          lecture
          role
          speciality
          fieldGroupName
          qutoe {
            fieldGroupName
            description
            title
          }
        }
        id
        title
        date
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
